.node {
    padding: 20px;
    border: solid 1px #ccc;
    border-radius: 8px;
}

.node-header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.node-index {
    color: #ccc;
}

.node-header-btns {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 30px;
}

.view-btn {
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.view-cancel-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: red;
}

.remove-btn {
    font-size: 24px;
    line-height: 30px;
    width: fit-content;
    cursor: pointer;
}

.node-title {
    width: 100%;
    min-width: 200px;
    outline: none;
    border: none;
    text-align: center;
    font-weight: bold;
    background-color: transparent;
}

.node-image-title {
    display: block;
    margin: auto;
    outline: none;
    border: none;
    text-align: center;
    font-weight: bold;
    color: blue;
    background-color: transparent;
}

.node-title::-webkit-scrollbar {
    width: 8px;  /* 스크롤바의 너비 */
}

.node-title::-webkit-scrollbar-thumb {
    height: 5%; /* 스크롤바의 길이 */
    background: #ddd; /* 스크롤바의 색상 */
    border-radius: 4px;
}

.node-title::-webkit-scrollbar-track {
    background: #ededed;  /*스크롤바 뒷 배경 색상*/
}

.options-wrap {
    display: flex;
}

.option-wrap {
    display: flex;
}

.node-option {
    width: 100px;
    outline: none;
    border: none;
    text-align: center;
    background-color: transparent;
}

.node-option::-webkit-scrollbar {
    width: 8px;  /* 스크롤바의 너비 */
}

.node-option::-webkit-scrollbar-thumb {
    height: 5%; /* 스크롤바의 길이 */
    background: #ddd; /* 스크롤바의 색상 */
    border-radius: 4px;
}

.node-option::-webkit-scrollbar-track {
    background: #ededed;  /*스크롤바 뒷 배경 색상*/
}

.option-btns {
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 4px;
}

.remove-option {
    height: fit-content;
    cursor: pointer;
}

.append-option {
    cursor: pointer;
}

.set-connection {
    cursor: pointer;
}