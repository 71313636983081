.rule-define-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.rule-define-header-wrap {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: solid 1px #dbdbdb;
    color: #424242;
}

.file-manager-wrap {
    padding: 20px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.file-list-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    max-height: 360px;
    overflow: auto;
}

.s3-file-item {
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    border: solid 1px #ccc;
    border-radius: 8px;
}

.s3-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    background-color: #ededed;
    cursor: pointer;
}

.s3-file-img {
    max-width: 100%;
    max-height: 100%;
}

.boilerplate-wrap {
    margin: 20px;
    border: solid 1px #dbdbdb;
    border-radius: 4px;
    height: 90%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  
  .boilerplate-list {
    flex: 1;
    display: flex;
    position: relative;
    overflow: auto;
  }
  
  .context-menu {
    position: absolute;
    padding: 8px 0px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .context-menu-item {
    padding: 8px 12px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    cursor: pointer;
    color: #424242;
  }
  
  .context-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .boilerplate-section {
    flex: 1;
    border-right: 1px solid #dbdbdb;
    display: flex;
    flex-direction: column;
    padding: 8px;
    padding-right: 12px;
    gap: 4px;
    text-align: left;
    min-width: 240px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
  }
  
  .boilerplate-section:last-child {
    border-right: none; /* 마지막 컬럼은 경계선 제거 */
  }
  
  .boilerplate-item {
    padding: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: top;
    color: #424242;
    flex-wrap: wrap;
    word-break: break-all;
  }
  
  .boilerplate-item.active {
    background-color: rgba(0, 0, 0, 0.1);
}
  
  .boilerplate-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  
  .boilerplate-item span:focus {
    outline-color: #1969db;
    padding: 0px 4px;
  }

  .boilerplate-item > span {
    flex:1;
  }

  .url-link {
    color: #1969db;
  }
  
  .boilerplate-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  
  .boilerplate-folder-tree {
    border-top: solid 1px #dbdbdb;
    height: 28px;
    display: flex;
    box-sizing: border-box;
    background-color: #ededed;
    padding: 4px 12px;
  }
  
  .folder-tree-item {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #7d7c7c;
    display: flex;
    align-items: center;
  }
  
  .folder-separator {
    margin: 0px 12px;
  }
  
  .no-files {
    font-weight: 500;
    margin: auto;
    margin-top: 20px;
  }