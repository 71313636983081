.editor-title-wrap {
    position: fixed;
    display: flex;
    margin-top: 12px;
    width: 100%;
    padding: 0px 20%;
    justify-content: space-between;
    z-index: 999;
}

.editor-title {
    font-weight: bold;
}

.set-init-index {
    width: 40px;
    margin-right: 12px;
}

.editor-btns-wrap {
    display: flex;
}

.save-btn {
    margin-right: 12px;
    cursor: pointer;
}

.file-select-button {
    margin-right: 12px;
    cursor: pointer;
}

.close-btn {
    cursor: pointer;
}

.react-flow__attribution {
    display: none;
}