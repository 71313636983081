.App {
  text-align: center;
}

.input-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.input-wrap div {
  width: 70px;
  text-align: right;
}

.input-wrap input{
  display: inline-block;
}