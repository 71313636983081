* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

#root{
  height: 100%;
}

.body {
  margin: 0;
  font-family: 'Noto Sans KR', "Times New Roman", Times, serif;
  height: 100%;
  min-width: 320px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  position: relative;
}

.service-title {
  display: inline-block;
  margin: 0;
  margin-left: 6px;
  color: rgba(0,0,0,.87);
  font-size: 20px;
  line-height: 40px;
  vertical-align: middle;
  flex-grow: 1;
  white-space: nowrap;
  font-weight: bold;
}

h2{
  margin: 0px;
}

button {
  font-family: 'Noto Sans KR', "Times New Roman", Times, serif;
}

textarea {
  font-family: 'Noto Sans KR', "Times New Roman", Times, serif;
}

textarea:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

/* Header styles */

header {
  display: flex;
  border-bottom: solid 3px #971B2F;
  padding: 12px 32px;
  height: 64px;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 24px;
  color: #333;
  white-space: nowrap;
}

.header-right {
  display: flex;
  white-space: nowrap;
}

.btn {
  background-color: #ededed;
  border: solid 1px #888888;
  border-radius: 4px;
  cursor: pointer;
}

.btn-wrap {
  position: absolute;
  top: 72px;
  left: 24px;
  display: flex;
  gap: 8px;
}

.query-btn {
  padding: 2px 6px;
  background-color: #f2f2f2;
  border: solid 1px #888888;
  border-radius: 4px;
  cursor: pointer;
}

.graph-edit-btn {
  padding: 2px 6px;
  background-color: #f2f2f2;
  border: solid 1px #888888;
  border-radius: 4px;
  cursor: pointer;
}

.reset-btn {
  padding: 2px 6px;
  background-color: #f2f2f2;
  border: solid 1px #888888;
  border-radius: 4px;
  cursor: pointer;
}

.mode-btn {
  padding: 2px 6px;
  background-color: #f2f2f2;
  border: solid 1px #888888;
  border-radius: 4px;
  cursor: pointer;
}

.mode-selector {
  display: flex;
  margin-right: 6px;
}

#logo {
  width: 100px;
  height: 28.58px;
  line-height: 40px;
  vertical-align: middle;
  margin-top: 6px;
  margin-right: 8px;
}

@media(max-width:420px) {
  .header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    white-space: nowrap;
  }
  
  .mode-selector{
    display: flex;
    margin-right: 0px;
    margin-bottom: 6px;
  }

  #logo {
    width: 80px;
    height: auto;
    line-height: 40px;
    vertical-align: middle;
    margin-top: 6px;
    margin-right: 4px;
  }
  
  .header-title {
    font-size: 22px;
  }
  
  }


.query-wrap {
  display: flex;
  align-items: center;
  padding: 8px 20px;
}

.query {
  margin-right: 12px;
}

.codetest-input-wrap {
  display: flex;
  align-items: center;
  padding: 8px 20px;
}

.query-button {
  background-color: #0069d9;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
}

.retrieve-output {
  max-height: 300px;
  overflow: auto;
  padding: 0px 20px;
}

.retrieve-output::-webkit-scrollbar {
  width: 4px;  /* 스크롤바의 너비 */
}

.retrieve-output::-webkit-scrollbar-thumb {
  height: 5%; /* 스크롤바의 길이 */
  background: rgba(186, 95, 109, 1); /* 스크롤바의 색상 */
  border-radius: 2px;
}

.retrieve-output::-webkit-scrollbar-track {
  background: #ededed;  /*스크롤바 뒷 배경 색상*/
}

/* Main styles */

main {
  padding: 0px;
  padding-bottom: 12px;
  height: 0;
  box-sizing: border-box;
  flex-grow: 1;
}

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgb(241,242,246);
  border-radius: 5px;
  overflow: hidden;
}

.chat__messages {
  flex: 1;
  margin-right: 2px;
  padding: 16px 20px 12px 20px;
  overflow-y: scroll;
}

.chat__message {
  display: flex;
  margin-bottom: 6px;
}

.chat__message-bubble {
  border-radius: 20px;
  padding: 10px 16px;
  max-width: 90%;
}

.chat__message p {
  margin: 0;
  white-space: pre-wrap;
  font-size: 14px;
  font-family: "NanumSquare";
}

.chat__message a {
  margin: 0;
  white-space: pre-wrap;
  font-size: 14px;
  font-family: "NanumSquare";
}

.chat__messages img {
  max-width: 100%;
  cursor: pointer;
}

.chat__message--sent {
  display: flex; 
  justify-content: flex-end;
}

.chat__message--received .chat__message-bubble {
  background-color: rgb(255, 255, 255);
  color: #000000;
  border-top-left-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  padding-bottom: 16px;
}

.chat__message--sent .chat__message-bubble {
  background-color: rgb(16, 123, 239);
  border-top-right-radius: 3px;
  color: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

/* Form styles */

.chat__form {
  display: flex;
  padding: 0px 20px;
  padding-top: 12px;
  border-top: solid 2px #971B2F;
  background-color: #fff;
}

.chat__input {
  flex: 1;
  border: none;
  font-size: 14px;
}

.chat__send {
  margin-left: 8px;
  padding: 8px 10px;
  border: none;
  background-color: #B2B4B8;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.chat__send:hover {
  background-color: #0069d9;
}


.chat__messages::-webkit-scrollbar {
    width: 4px;  /* 스크롤바의 너비 */
}

.chat__messages::-webkit-scrollbar-thumb {
    height: 5%; /* 스크롤바의 길이 */
    background: rgba(186, 95, 109, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.chat__messages::-webkit-scrollbar-track {
    background: #ededed;  /*스크롤바 뒷 배경 색상*/
}

.chat__input::-webkit-scrollbar {
    width: 4px;  /* 스크롤바의 너비 */
}

.chat__input::-webkit-scrollbar-thumb {
    height: 5%; /* 스크롤바의 길이 */
    background: rgba(186, 95, 109, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.chat__input::-webkit-scrollbar-track {
    background: #ededed;  /*스크롤바 뒷 배경 색상*/
}

.inbody-result-editor {
  border-top: solid 1px #971B2F;
  padding: 20px;
}

.result-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.line-header {
  padding-bottom: 6px;
  border-bottom: solid 1px #888888;
  margin-bottom: 12px;
}

.recommend_question {
  padding: 20px;
  border-top: solid 1px #971B2F;
  background-color: #fff;
}

.question-list {
  max-height: 250px;
  overflow: auto;
}

.question {
  background-color: #f2f2f2;
  border-radius: 0.5rem;
  padding: 8px 16px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 12px;
}

.question.default {
  background-color: #ddd;
}

.question:last-child {
  margin-bottom: 0;
}

.question-arrow-wrap{
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  margin-top: -8px;
  margin-bottom: -20px;
  justify-content: center;
}

.question-arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000;
}

.question-arrow.up {
  transform: rotate(0deg);
}

.question-arrow.down {
  transform: rotate(180deg);
}

.question-header{
  margin-bottom: 12px;
  font-size: 14px;
  cursor: pointer;
}

.question:hover {
  background-color: #e6e6e6;
}

.loading-wrap {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.05);
}

.loading-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-color: transparent;
}